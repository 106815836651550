export const PARAGRAPHS = [
  `I'm a full-stack developer with a passion for coding, hacking, and solving problems. I started this journey in my early teenage years to hack video-games, realizing that with coding I could hack, improve and create awesome stuff. My passion quickly evolved into creating software to help my parents' business and into pursuing a degree in CS in the US, winning Hackathons and doing startups.`,
  `I'm now a seasoned hacker, full-stack and mobile developer with years of experience in several languages. My area of expertise, and the area I currently have the most fun is in Full-Stack Engineering, building complex serverless apps in Next, React, and GraphQL.`,
];

export const TLDR = `I'm a javascript master that loves building cool serverless applications in a React/Next front-end and a GQL backend. Serverless/JAMstack ❤`;

export const PARTICULAR_SKILLS = [
  {
    name: 'Languages',
    skills: [
      'Javascript',
      'Typescript',
      'GraphQL',
      'HTML',
      'CSS',
      'SCSS',
      'Ruby',
      'JAVA',
      'C/C++',
      'C#',
      'Swift',
      'PHP',
    ],
  },
  {
    name: 'Favorites',
    skills: [
      'React',
      'Next',
      'NodeJS',
      'GraphQL',
      'Serverless',
      'CI/CD',
      'Tailwind',
      'Flexbox',
      'Redux',
      'Webpack',
      'React-Native',
      'Rails',
      'Express',
      'Mongoose',
    ],
  },
  {
    name: 'Platforms',
    skills: ['Netlify', 'Amazon AWS', 'Digital Ocean', 'FireBase', 'Docker'],
  },
  {
    name: 'Databases',
    skills: ['MongoDB', 'MySQL', 'Microsoft SQL', 'Reddis'],
  },
];

export const NAVBAR_LINKS = [
  {
    url: '/',
    text: 'Home',
  },
  {
    url: '/bots',
    text: 'Discord Bots',
  },
];

const covidbotMD = `
## Covid bot
A discord bot that returns recent coronavirus data from [worldometers.com](https://www.worldometers.info/coronavirus/)

[Click here to add to your server!](https://discordapp.com/oauth2/authorize?client_id=697553145139560588&permissions=0&scope=bot)

🚧 **Bot has been shut down** 🚧

### Commands
\`!covid\` Returns world covid stats

\`!covid <country>\` Returns covid stats regarding that country
`;

const demonslayerbotMD = `
## Demon Slayer bot

A discord bot that turns Discord into an RPG in the Demon Slayer universe!
Join the Demon Slayer Corps, choose a breathing style and  hunt demons down to increase your experience, raise through the ranks and make some money!

Ranks are reset every couple months and special rewards will be given to those who reach the rank of Hashira!

🚧 **Unreleased** 🚧

### Commands
🚧 **Unreleased** 🚧
`;

export const BOTS = [
  {
    name: 'Covid Bot',
    pic: 'https://i.imgur.com/sBVfA89.jpg',
    id: 'covidbot',
    info: covidbotMD,
  },
  {
    name: 'DemonSlayer Bot',
    pic: 'https://i.imgur.com/Ca50lpa.png',
    id: 'demonslayerbot',
    info: demonslayerbotMD,
  },
];
