import { useState } from 'react';
import { NAVBAR_LINKS } from '../constants';

export const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const onHamburgerClick = () => setShowMenu(!showMenu);

  const Link = ({ text, url }: { text: string; url: string }) => (
    <a
      className="flex border-t-[1px] border-black py-1 px-4 md:border-none md:p-0"
      href={url}
      key={url}
    >
      {text}
    </a>
  );

  return (
    <div className="w-full bg-[#1f1f1f] md:px-28 py-4 flex flex-row justify-between md:justify-start flex-wrap md:flex-nowrap text-white">
      <a href="/" className="px-4">
        コード 忍者
      </a>
      <div className="flex flex-col md:hidden items-end justify-center px-4">
        <div className="fa fa-bars" onClick={onHamburgerClick} />
      </div>
      <div
        className={`${
          showMenu ? 'block' : 'hidden'
        } mt-4 -mb-3 w-full md:w-auto md:mt-0 md:flex md:flex-row md:gap-8 md:px-8`}
      >
        {NAVBAR_LINKS.map(Link)}
      </div>
    </div>
  );
};
