import React from 'react';
import { BOTS } from '../constants';

type BotLinkProps = {
  pic: string;
  id: string;
  name: string;
};

const BotLink = ({ pic, id, name }: BotLinkProps) => {
  return (
    <div
      className="flex flex-col m-6 text-white items-center justify-center"
      key={name}
    >
      <a href={`/bots/${id}`}>
        <img
          className={`w-48 h-48 object-cover rounded-[50%] shadow-bot-picture-shadow`}
          src={pic}
          alt={`${name} bot icon`}
        />
        <p className="text-center mt-8 uppercase">{name}</p>
      </a>
    </div>
  );
};

export const Bots = () => (
  <div className="flex flex-row flex-wrap justify-center items-center">
    {BOTS.map(BotLink)}
  </div>
);
