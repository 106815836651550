import { useParams } from 'react-router';
import { BOTS } from '../constants';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const Bot = () => {
  const { id } = useParams();

  const { pic, name, info } = BOTS.find((b) => b.id === id) || {};

  return (
    <div className="text-white flex flex-col items-center justify-center pt-10 gap-4">
      <img
        className={`w-48 h-48 object-cover rounded-[50%] shadow-bot-picture-shadow`}
        src={pic}
        alt={`${name} bot icon`}
      />
      <ReactMarkdown
        className="max-w-[640px] text-white prose prose-h2:text-white prose-strong:text-white prose-h3:text-white prose-a:text-blue-400 prose-code:text-gray-500"
        remarkPlugins={[remarkGfm]}
        children={info}
      />
    </div>
  );
};
