import React, { ReactNode } from 'react';
import './App.scss';
import { PARAGRAPHS, PARTICULAR_SKILLS, TLDR } from './constants';

const SkillColumn = ({ name, skills }: { name: string; skills: string[] }) => (
  <div key={name} className="mb-8 md:mb-0">
    <p className="text-2xl font-medium mt-2 mb-4">{name}</p>
    <ul className="list-disc">
      {skills.map((s) => (
        <li className="ml-4" key={s}>
          {s}
        </li>
      ))}
    </ul>
  </div>
);

const Section = ({ children }: { children: ReactNode }) => (
  <div className="mt-4 mb-8 last:mb-0">{children}</div>
);

const SectionHeader = ({ children }: { children: ReactNode }) => (
  <p className="text-3xl mb-1">{children}</p>
);

export const App = () => {
  return (
    <div className="w-full flex flex-col items-center text-white">
      <div className="max-w-3xl mt-4 p-4">
        <Section>
          <SectionHeader>About</SectionHeader>
          {PARAGRAPHS.map((paragraph) => (
            <p className="mb-4 last:mb-0" key={paragraph}>
              {paragraph}
            </p>
          ))}
        </Section>
        <Section>
          <SectionHeader>TL;DR</SectionHeader>
          <p>{TLDR}</p>
        </Section>
        <Section>
          <SectionHeader>Skills</SectionHeader>
          <div className="flex flex-col md:flex-row justify-between">
            {PARTICULAR_SKILLS.map(({ name, skills }) => (
              <SkillColumn name={name} skills={skills} key={name} />
            ))}
          </div>
        </Section>
      </div>
    </div>
  );
};
